
<template>
  <layout>
    <v-card>
        <v-card-title>
            <span class="title font-weight-light">บริหารโครงการ</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
          <v-icon
                small
                left
            >
                mdi-book-open-variant
            </v-icon>
            <span class="subtitle-1">ข้อมูล</span>
        </v-card-title>
        <v-divider class="mx-4" />
        
        <v-container class="body-2">
            <!-- <v-row>
                <v-col cols="3">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        label="ค้นหาจากชื่อ"
                        outlined
                        dense
                        v-model="keywordName"
                    ></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        label="ค้นหาจากนามสกุล"
                        outlined
                        dense
                        v-model="keywordLastName"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-btn outlined color="green" @click="search()">ค้นหา</v-btn>
                </v-col>
            </v-row> -->
            <v-btn depressed color="primary" to="/project/add" class="mb-2">
                + เพิ่มข้อมูลโครงการ
            </v-btn>
            <v-row>
                <v-col>
                    <v-card outlined>
                        <v-data-table
                            :headers="headers"  
                            :items="projectData"
                        >
                        
                        <template v-slot:item.link_project_file="{ item }">
                          <a :href="item.link_project_file" target="_blank">ไฟล์แนบ</a>
                        </template>
                        <template v-slot:item.link_board_file="{ item }">
                          <a :href="item.link_board_file" target="_blank">ไฟล์แนบ</a>
                        </template>
                        <template v-slot:item.link_project_summaryFile="{ item }">
                          <a :href="item.link_project_summaryFile" target="_blank">ไฟล์แนบ</a>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div v-if="storeUser.user_group == 4">
                            <v-btn :to="'/project/edit/' + item.id" depressed class="mr-2 orange darken-2 white--text">
                                แก้ไข
                            </v-btn>

                            <v-btn @click="deleteData(item.id)" depressed class="mr-2 red darken-2 white--text">
                                ลบ
                            </v-btn>
                          </div>
                        </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            
        </v-container>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    data: () => ({
        storeUser: null,
        headers: [
            {
                text: "ชื่อโครงการ",
                value: "project_name"
            },
            {
                text: "จำนวนงบประมาณ",
                value: "budget"
            },
            {
                text: "สถานะการดำเนินการ",
                value: "project_status"
            },
            {
                text: "กิจกรรม",
                value: "event"
            },
            {
                text: "ระยะเวลาเริ่มดำเนินการ",
                value: "start_project"
            },
            {
                text: "ระยะเวลาสิ้นสุด",
                value: "end_project"
            },
            {
                text: "ผู้รับผิดชอบ",
                value: "responsible_person"
            },
            {
                text: "ไฟล์โครงการ",
                value: "link_project_file"
            },
            {
                text: "คณะกรรมการ",
                value: "link_board_file"
            },
            {
                text: "ไฟล์สรุปโครงการ",
                value: "link_project_summaryFile"
            },
            {
                text: "จัดการ",
                value: "actions",
                align: "center"
            }
        ],
        projectData: [],
        keywordName: '',
        keywordLastName: '',
    }),
    created() {
      this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getData();
      this.$store.commit('setLoading', false)
    },
    methods: {
      async search() {
        try {
          const req = {
            params: {
              firstname: this.keywordName,
              lastname: this.keywordLastName,
            }
          }
          const res = (await Api().get("staff/search", req)).data;

          if (res.status == 'success') {
            this.staffData = res.data;
          } else {
            console.log(res.data);
          }
        } catch (err) {
          console.log(err.message);
        }
      },
      async getData() {
        try {
          const res = (await Api().get("project")).data;

          console.log('data', res);

          if (res.status == 'success') {
            this.projectData = res.data;
          } else {
            console.log(res.data);
          }
        } catch (err) {
          console.log(err.message);
        }
      },
      async deleteData(id){
        if (await this.$refs.confirm.open('ยืนยัน', 'ต้องการลบข้อมูลหรือไม่', { color: 'primary', noconfirm: false })) {
          this.$store.commit('setLoading', true)

          const req = {}

          let res = (await Api().post("/project/del/" + id, req)).data;

          if (res.status == 'success') {
            this.$store.commit('setLoading', false)
            await this.getData();
          }else{
            this.$store.commit('setLoading', false)
          }
        }
      },
    }
};
</script>